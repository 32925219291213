import React from "react";
import "./AccessibilityStatement.css";
import { Helmet } from "react-helmet";
import Presentation from "../UI/Presentation/Presentation";
import HomeIlustration from "../UI/HomeIlustration/HomeIlustration";
import { Trans, msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

function AccessibilityStatement() {
    const { _ } = useLingui();
   
    return (
        <div id="privacy-policy-main" className="privacy-policy-page-container">
            <Helmet>
                <title>
                    {_(msg`Accessibility Statement | A11ysolutions`)}
                </title>
            </Helmet>

            <div className="privacy-policy-presentation-container">
                <div className="page-top">
                    <Presentation
                        title={t`Accessibility Statement`}
                        texts={[""]}
                        isLink
                        buttonText={t`Get a free consultation`}
                        className={t`privacy-policy-presentation-sign`}
                    />
                </div>
                <div className="privacy-policy-ilustration-container">
                    <HomeIlustration
                        firstPillText={t`Secure`}
                        secondPillText={t`Reliable`}
                        thirdPillText={t`Ethical`}
                    />
                </div>
            </div>
            <div className="privacy-policy-statement">
                <div>
                    <h2>
                        <Trans>
                            Accessibility Statement for a11ySolutions, llc
                        </Trans>
                    </h2>
                    <p>
                        <Trans>Last updated March 9, 2023.</Trans>
                    </p>
                    <p>
                        <Trans>
                            At a11ySolutions llc, we are committed to ensuring
                            that all visitors to our website have access to the
                            information and services provided on our site,
                            regardless of any disabilities they may have. We
                            strive to adhere to the Web Content Accessibility
                            Guidelines (WCAG) 2.1 Level AA to ensure that our
                            website is accessible to everyone.
                        </Trans>
                    </p>

                    <h3>
                        <Trans>Summary of Accessibility Features</Trans>
                    </h3>
                    <ul>
                        <li>
                            <Trans>Alt text descriptions for all images</Trans>
                        </li>
                        <li>
                            <Trans>
                                Descriptive link text that provides context for
                                the destination page
                            </Trans>
                        </li>
                        <li>
                            <Trans>Consistent navigation and layout </Trans>
                        </li>
                        <li>
                            <Trans>
                                Use of headings and subheadings to organize
                                content and improve readability
                            </Trans>
                        </li>
                        <li>
                            <Trans>
                                Keyboard navigation support to allow users to
                                navigate the website without a mouse
                            </Trans>
                        </li>
                        <li>
                            <Trans>
                                Use of high-contrast color combinations for text
                                and background
                            </Trans>
                        </li>
                        <li>
                            <Trans>
                                Resizable text to enable users to adjust font
                                size for easier readability
                            </Trans>
                        </li>
                    </ul>

                    <h3>
                        <Trans>How did we do it</Trans>
                    </h3>
                    <p>
                        <Trans>
                            Our accessibility experts conducted manual testing
                            on a Windows or Mac computer using NVDA, JAWs, or
                            VoiceOver, as appropriate. Proprietary software is
                            used for all automated testing.
                        </Trans>
                    </p>

                    <h3>
                        <Trans>How Can We Help?</Trans>
                    </h3>
                    <p>
                        <Trans>
                            We welcome any comments, questions, or feedback on
                            our site. If you notice aspects of our site that
                            aren’t working for you or your assistive technology,
                            please let us know. We’d like to hear from you in
                            any of the following ways:
                        </Trans>
                    </p>
                    <p className="contact-info">
                        <Trans>
                            <b>Call us at:</b> +1 407-535-1932
                        </Trans>
                    </p>
                    <p className="contact-info">
                        <Trans>
                            <b>Email us:</b>{" "}
                            <a href="mailto:allycontact@allysolutions.com">
                                allycontact@allysolutions.com
                            </a>
                        </Trans>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AccessibilityStatement;
