import React from "react";
import "./LinkButton.css";
import { Link } from "react-router-dom";

const LinkButton = React.forwardRef(
  (
    {
      id,
      href,
      className = "",
      style,
      onClick,
      children,
      ariaLabel,
      variant = "dark",
      size = "medium",
      isExternal = true,
    },
    ref
  ) => {
    const handleClick = (event) => {
      if (!href) {
        event.preventDefault();
      }
      if (onClick) {
        onClick(event);
      }
    };

    if (!isExternal)
      return (
        <a
          id={id}
          href={href}
          className={`a11y-link a11y-link-${size} a11y-link-${variant} ${className}`}
          style={{ ...style }}
          aria-label={ariaLabel}
        >
          {children}
        </a>
      );

    return (
      <a
        id={id}
        ref={ref}
        href={href || "#"}
        // tabIndex={href ? undefined : '0'}
        className={`a11y-link a11y-link-${size} a11y-link-${variant} ${className}`}
        style={{ ...style }}
        onClick={handleClick}
        target={href ? "_blank" : "_self"}
        rel={href ? "noopener noreferrer" : ""}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    );
  }
);

export default LinkButton;
