import React, { useEffect } from "react";
import "./Sitemap.css";
import openIcon from "../../assets/open-icon.svg";
import { Helmet } from "react-helmet";
import { Trans, msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Link } from "react-router-dom";

function Sitemap() {
    const { _ } = useLingui();

    return (
        <div id="sitemap-container">
            <Helmet>
                <title>{t`Sitemap | A11ysolutions`}</title>
            </Helmet>

            <h1 tabIndex="-1">
                <Trans>Sitemap</Trans>
            </h1>

            <div className="sitemap-listing">
                <ul>
                    <li>
                        <a id="first_link" href="/#our-services">
                            <Trans>Services</Trans>
                        </a>
                        <ul>
                            <li>
                                <a href="/accessibility">
                                    <Trans>Accessibility</Trans>
                                </a>
                            </li>
                            <li>
                                <a href="/functional">
                                    <Trans>Functional</Trans>
                                </a>
                            </li>
                            <li>
                                <a href="/automation">
                                    <Trans>Automation</Trans>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="/standards#compliance-section">
                            <Trans>Standards</Trans>
                        </a>
                        <ul>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.ada.gov/"
                                    aria-label={_(
                                        msg`Americans with Disabilities Act (ADA) (opens new window)`
                                    )}
                                >
                                    <Trans>
                                        Americans with Disabilities Act (ADA)
                                    </Trans>
                                    <img
                                        src={openIcon}
                                        alt=""
                                        className="openIcon"
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.section508.gov/manage/laws-and-policies/"
                                    aria-label={_(
                                        msg`Section 508 Amendment to the Rehabilitation Act (opens new window)`
                                    )}
                                >
                                    <Trans>
                                        Section 508 Amendment to the
                                        Rehabilitation Act
                                        <img
                                            src={openIcon}
                                            alt=""
                                            className="openIcon"
                                        />
                                    </Trans>
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.w3.org/TR/WCAG22/"
                                    aria-label={_(
                                        msg`Web Content Accessibility Guidelines (WCAG) 2.2 (opens new window)`
                                    )}
                                >
                                    <Trans>
                                        Web Content Accessibility Guidelines
                                        (WCAG) 2.2
                                        <img
                                            src={openIcon}
                                            alt=""
                                            className="openIcon"
                                        />
                                    </Trans>
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://digital-strategy.ec.europa.eu/en/policies/latest-changes-accessibility-standard"
                                    aria-label={_(
                                        msg`EN 301 549 European Standard (opens new window)`
                                    )}
                                >
                                    <Trans>
                                        EN 301 549 European Standard
                                        <img
                                            src={openIcon}
                                            alt=""
                                            className="openIcon"
                                        />
                                    </Trans>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#name">
                            <Trans>Get a free consultation</Trans>
                        </a>
                    </li>
                    <li>
                        <a href="/privacy-policy">
                            <Trans>Privacy Policy</Trans>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sitemap;
