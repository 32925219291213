import React from "react";
import Pill from "../Pill/Pill";
import "./HomeIlustration.css";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
function HomeIlustration({
    firstPillText,
    secondPillText,
    thirdPillText,
    src,
}) {
    const { _ } = useLingui();
    return (
        <div className="home-ilustration-container">
            <div style={{ position: "relative", marginTop: "50px" }}>
                <img
                    src={src ? src : "/coding-man@2x.png"}
                    className="home-ilustration"
                    alt={_(
                        msg`We work passionately on functional testing, accessibility testing, and automation testing services.`
                    )}
                    style={{ zIndex: "2" }}
                />

                {firstPillText && (
                    <Pill className="home-ilustration-functionality-pill">
                        {firstPillText}
                    </Pill>
                )}
                {secondPillText && (
                    <Pill
                        className="home-ilustration-accessibility-pill"
                        dotPosition="top-right"
                    >
                        {secondPillText}
                    </Pill>
                )}
                {thirdPillText && (
                    <Pill
                        className="home-ilustration-automation-pill"
                        dotPosition="top-left"
                    >
                        {thirdPillText}
                    </Pill>
                )}

                {!src && (
                    <>
                        <div className="home-ilustration-small-circle"></div>
                        <div style={{ width: "100%" }}>
                            <div className="home-ilustration-large-circle"></div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default HomeIlustration;
