import React from "react";
import "./Navbar.css";
import Logo from "../Logo/Logo";
import ToggleMenu from "../ToggleMenu/ToggleMenu";
// import Button from '../Button/Button'
import LinkButton from "../LinkButton/LinkButton";
import { Trans, t } from "@lingui/macro";
import LangButton from "../LangButton/LangButton";
import { Link } from "react-router-dom";

function Navbar({ onClickMenu, onClickContactUs, menuButtonRef }) {
  return (
    <div className="a11y-navbar">
      <div className="a11y-navbar-logo">
        <Link
          style={{
            background: "none",
            border: "none",
            textDecoration: "none",
          }}
          to="/"
        >
          <Logo
            className="a11y-navbar-logo-img"
            style={{ width: "60px", height: "24px" }}
            altText={t`A11ySolutions home`}
          />
        </Link>
      </div>

      <div className="a11y-navbar-toggle">
        <nav aria-label="Main Menu">
          <ul className="a11y-navbar-items">
            <li>
              <a href="/#why-choose-us">
                <Trans>Why choose us</Trans>
              </a>
            </li>
            <li>
              <a href="/#our-services">
                <Trans>Our services</Trans>
              </a>
            </li>
            <li>
              <a href="/#our-products">
                <Trans>Our products</Trans>
              </a>
            </li>
            <li>
              <a href="/#clients">
                <Trans>Clients</Trans>
              </a>
            </li>
            <li>
              <LinkButton
                id="contact-us-navbar-button"
                className="a11y-navbar-contact link"
                onClick={onClickContactUs}
              >
                <Trans>Contact us</Trans>
              </LinkButton>
            </li>
            <li>
              <LangButton />
            </li>
          </ul>
        </nav>
        <ToggleMenu onClick={onClickMenu} menuButtonRef={menuButtonRef} />
      </div>
    </div>
  );
}

export default Navbar;
